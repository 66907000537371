import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Component = styled.aside`
  padding-top: 4rem;
  margin-bottom: 10rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    max-width: 40rem;
    padding-top: 10rem;
    margin-bottom: 0;
  }
`;

const Title = styled.h3`
  margin-bottom: 4rem;
  padding-bottom: 1.2rem;
  border-bottom: 3px solid ${({ theme }) => theme.colors.border};
  color: ${({ theme }) => theme.colors.brand};
  font-family: ${({ theme }) => theme.fontFamily};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: 1.4rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    font-size: 1.7rem;
  }
`;

const Grid = styled.div`
  display: flex;
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  width: 33.33%;
  text-align: center;
`;

const Icon = styled.div`
  margin-bottom: 2.2rem;
  height: 5rem;
`;

const Text = styled.p`
  opacity: 0.7;
  color: ${({ theme }) => theme.colors.brand};
  font-family: ${({ theme }) => theme.fontFamily};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: 1.4rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    font-size: 1.7rem;
  }
`;

const Table = ({ modalities }) => {
  return (
    <Component>
      <Title>MultiModality</Title>
      <Grid>
        {modalities.map((item, i) => (
          <Item key={i}>
            <Icon>
              {item.slug === "voice" && (
                <svg
                  width="45"
                  height="56"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0)" fill="#FFDF00">
                    <path d="M15.68.577a1.764 1.764 0 00-1.765 1.764v40.587a1.764 1.764 0 103.53 0V2.34A1.766 1.766 0 0015.68.577zM8.914 8.468a1.764 1.764 0 00-1.764 1.765v24.803a1.765 1.765 0 003.529 0V10.233a1.762 1.762 0 00-1.765-1.765zM22.443 8.468a1.764 1.764 0 00-1.764 1.765v24.803a1.765 1.765 0 003.529 0V10.233a1.764 1.764 0 00-1.765-1.765zM35.972 8.468a1.764 1.764 0 00-1.764 1.765v24.803a1.765 1.765 0 003.529 0V10.233a1.764 1.764 0 00-1.765-1.765zM29.207 15.233a1.764 1.764 0 00-1.765 1.764v11.274a1.764 1.764 0 103.53 0V16.997a1.762 1.762 0 00-1.765-1.764zM42.738 15.233a1.764 1.764 0 00-1.765 1.764v11.274a1.764 1.764 0 103.53 0V16.997a1.764 1.764 0 00-1.765-1.764zM2.15 15.233a1.764 1.764 0 00-1.764 1.764v11.274a1.764 1.764 0 003.53 0V16.997a1.764 1.764 0 00-1.765-1.764z" />
                  </g>
                  <defs>
                    <clipPath id="clip0">
                      <path
                        fill="#fff"
                        transform="translate(.385 .577)"
                        d="M0 0h44.115v55.144H0z"
                      />
                    </clipPath>
                  </defs>
                </svg>
              )}
              {item.slug === "augmented-reality" && (
                <svg
                  width="41"
                  height="46"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M40.1 10.005a1.186 1.186 0 01.67 1.096v8.322a1.192 1.192 0 11-2.384 0v-6.417l-5.428 2.714a1.192 1.192 0 11-1.067-2.133l5.021-2.51-5.02-2.51a1.192 1.192 0 111.066-2.134l7.143 3.572zm.67 24.893a1.187 1.187 0 01-.67 1.096l-7.142 3.572a1.192 1.192 0 11-1.067-2.133l5.021-2.51-5.02-2.511a1.192 1.192 0 011.066-2.133l5.428 2.714v-6.417a1.192 1.192 0 112.385 0v8.322zm-36.679.024l5.02 2.51a1.192 1.192 0 11-1.065 2.134L.903 35.994a1.187 1.187 0 01-.67-1.096v-8.322a1.192 1.192 0 112.384 0v6.417l5.429-2.714a1.192 1.192 0 011.066 2.133l-5.021 2.51zM2.617 13.006v6.417a1.192 1.192 0 11-2.384 0V11.1a1.186 1.186 0 01.346-.865c.1-.1.208-.175.324-.231l7.143-3.572a1.192 1.192 0 011.066 2.133l-5.021 2.51 5.02 2.511a1.192 1.192 0 01-1.065 2.133l-5.429-2.714zM19.947.483a1.187 1.187 0 011.11 0l7.132 3.566a1.192 1.192 0 11-1.067 2.133l-5.428-2.715v6.417a1.192 1.192 0 11-2.385 0V3.467l-5.428 2.715a1.192 1.192 0 11-1.066-2.133L19.947.483zm1.11 45.033a1.186 1.186 0 01-1.11 0l-7.132-3.566a1.192 1.192 0 111.066-2.133l5.428 2.715v-6.417a1.192 1.192 0 112.385 0v6.417l5.428-2.714a1.192 1.192 0 111.067 2.132l-7.133 3.566zm.637-24.164v6.417a1.192 1.192 0 11-2.385 0v-6.417l-6.494-3.247a1.192 1.192 0 011.066-2.133l6.62 3.31 6.621-3.31a1.192 1.192 0 011.067 2.133l-6.495 3.247z"
                    fill="#FFDF00"
                  />
                </svg>
              )}
              {item.slug === "scanning" && (
                <svg
                  width="36"
                  height="36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M34.653 13.23a1.192 1.192 0 01-1.192-1.191V6.077A3.581 3.581 0 0029.884 2.5h-5.962a1.192 1.192 0 010-2.385h5.962a5.969 5.969 0 015.961 5.962v5.962a1.192 1.192 0 01-1.192 1.192zM2.461 12.04V6.077A3.58 3.58 0 016.038 2.5h5.961a1.192 1.192 0 100-2.385H6.038A5.969 5.969 0 00.076 6.077v5.962a1.192 1.192 0 002.385 0zm10.73 22.653A1.192 1.192 0 0012 33.5H6.038a3.58 3.58 0 01-3.577-3.577v-5.961a1.192 1.192 0 00-2.385 0v5.961a5.969 5.969 0 005.962 5.962h5.961a1.192 1.192 0 001.193-1.193zm22.654-4.769v-5.961a1.192 1.192 0 10-2.384 0v5.961a3.581 3.581 0 01-3.577 3.577h-5.962a1.192 1.192 0 100 2.385h5.962a5.969 5.969 0 005.961-5.962zm0-11.923a1.192 1.192 0 00-1.192-1.192H1.268a1.192 1.192 0 100 2.384h33.385A1.192 1.192 0 0035.845 18z"
                    fill="#FFDF00"
                  />
                </svg>
              )}
            </Icon>
            <Text>{item.title}</Text>
          </Item>
        ))}
      </Grid>
    </Component>
  );
};

Table.propTypes = {
  modalities: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
};

export default Table;
