import React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import { findIndex } from "lodash";
import PropTypes from "prop-types";
import styled from "styled-components";

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 15rem;
`;

const FooterLeft = styled.div`
  text-align: right;
  svg {
    margin-right: 1.4rem;
  }
`;

const FooterRight = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-end;
  svg {
    margin-left: 1.4rem;
  }
`;

const FooterItemTitle = styled.p`
  transition: ${({ theme }) => theme.animations.duration} color ease;
  display: block;
  margin-bottom: 0.6rem;
  color: ${({ theme }) => theme.colors.whiteOpaque};
  font-size: 1.8rem;
  font-family: ${({ theme }) => theme.fontFamily};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  @media (min-width: ${({ theme }) => theme.breakpoints.small}) {
    font-size: 2.4rem;
  }
`;

const FooterItemLink = styled((props) => <Link {...props} />)`
  color: ${({ theme }) => theme.colors.brand};
  font-size: 2rem;
  font-family: ${({ theme }) => theme.fontFamily};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  text-decoration: none;
  &:hover ${FooterItemTitle}, &:focus ${FooterItemTitle} {
    color: ${({ theme }) => theme.colors.white};
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.small}) {
    font-size: 2.8rem;
  }
`;

const Pagination = ({ slug }) => {
  const data = useStaticQuery(graphql`
    {
      allWorkflowsJson {
        edges {
          node {
            title
            slug
            order
          }
        }
      }
    }
  `);
  const { edges } = data.allWorkflowsJson;
  const items = edges.sort((a, b) => a.node.order - b.node.order);
  const currentIndex = findIndex(items, (item) => item.node.slug === slug);
  const getPrevious = () =>
    items[currentIndex - 1] ? items[currentIndex - 1] : items[items.length - 1];
  const getNext = () =>
    items[currentIndex + 1] ? items[currentIndex + 1] : items[0];
  const previous = getPrevious();
  const next = getNext();
  return (
    <Footer>
      <FooterLeft>
        <FooterItemLink to={`/${previous.node.slug}/`}>
          <FooterItemTitle>Prev</FooterItemTitle>
          <div>
            <svg
              width="26"
              height="17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.06 16.355a1.135 1.135 0 00.01-1.598L3.79 9.469h20.094c.616 0 1.12-.504 1.12-1.13 0-.625-.504-1.128-1.12-1.128H3.799l5.28-5.289a1.143 1.143 0 00-.01-1.597A1.124 1.124 0 007.48.333L.325 7.541a1.268 1.268 0 00-.234.356A1.132 1.132 0 00.325 9.12l7.155 7.207c.426.452 1.138.46 1.58.027z"
                fill="#FFDF00"
              />
            </svg>
            {previous.node.title}
          </div>
        </FooterItemLink>
      </FooterLeft>
      <FooterRight>
        <FooterItemLink to={`/${next.node.slug}`}>
          <FooterItemTitle>Next</FooterItemTitle>
          <div>
            {next.node.title}
            <svg
              width="25"
              height="17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.943.482a1.135 1.135 0 00-.009 1.598l5.28 5.288H1.12c-.616 0-1.12.504-1.12 1.13 0 .624.504 1.128 1.12 1.128h20.085l-5.28 5.288a1.143 1.143 0 00.01 1.598 1.124 1.124 0 001.588-.009l7.156-7.207c.095-.104.173-.217.234-.356a1.132 1.132 0 00-.235-1.224L17.524.508a1.106 1.106 0 00-1.58-.026z"
                fill="#FFDF00"
              />
            </svg>
          </div>
        </FooterItemLink>
      </FooterRight>
    </Footer>
  );
};

Pagination.propTypes = {
  slug: PropTypes.string.isRequired,
};

export default Pagination;
