import * as React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import Layout from "../components/layout";
import Pagination from "../components/pagination";
import Table from "../components/table";
import Touts from "../components/touts";
import Feature from "../components/feature";
import Seo from "../components/seo";

const Container = styled.div`
  max-width: 150rem;
  margin: 15rem auto 10rem auto;
  padding: 0 1.5rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin: 15rem auto 10rem auto;
    padding: 0 6rem;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    margin: 20rem auto 10rem auto;
  }
`;

const Wrapper = styled.div`
  position: relative;
  max-width: 200rem;
  top: -150px;
  margin: 0 auto;
`;

const Banner = styled.div`
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 450px;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("/images/${(props) => props.mobile}");
  @media (min-width: ${({ theme }) => theme.breakpoints.small}) {
    height: 500px;
    background-image: url("/images/${(props) => props.tablet}");
    background-size: contain;
    background-position: right top;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    height: 610px;
    background-image: url("/images/${(props) => props.desktop}");
  }
`;

const Body = styled.div`
  padding-top: 10rem;
  margin-bottom: 3rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    padding-top: 0;
  }
`;

const Grid = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    flex-direction: row;
  }
`;

const GridItem = styled.div`
  width: 100%;
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    width: 50%;
  }
`;

const Icon = styled.div`
  height: 10rem;
  width: 10rem;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 3rem;
  background: #3e3e3e;
  border-radius: 100%;
  background-image: url("/animations/${(props) => props.artwork}");
  background-position: center;
  background-size: cover;
  box-shadow: inset 3px 3px 4px #000;
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    height: 15rem;
    width: 15rem;
  }
`;

const Title = styled.h1`
  color: ${({ theme }) => theme.colors.brand};
  font-family: ${({ theme }) => theme.fontFamily};
  font-weight: ${({ theme }) => theme.fontWeights.black};
  font-size: 4.8rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin-bottom: 3rem;
    font-size: 7.2rem;
  }
`;

const RichText = styled.div`
  max-width: 63rem;
  > p {
    margin-bottom: 2rem;
    color: ${({ theme }) => theme.colors.white};
    font-size: 1.5rem;
    font-family: ${({ theme }) => theme.fontFamily};
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    line-height: 1.5;
    @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
      margin-bottom: 3.3rem;
      font-size: 2rem;
      line-height: 1.6;
    }
  }
  ul {
    margin-bottom: 2rem;
    @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
      margin-bottom: 3.3rem;
    }
  }
  li {
    position: relative;
    margin-bottom: 2rem;
    &:before {
      position: absolute;
      top: -1rem;
      font-size: 4rem;
      color: #c4c4c4;
      content: "-";
    }
  }
  li > p {
    margin-left: 3rem;
    color: ${({ theme }) => theme.colors.white};
    font-family: ${({ theme }) => theme.fontFamily};
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    font-size: 1.5rem;
    line-height: 1.5;
    @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
      font-size: 2rem;
      line-height: 1.6;
    }
  }
  a {
    color: ${({ theme }) => theme.colors.brand};
    text-decoration: none;
    border-bottom: 3px solid ${({ theme }) => theme.colors.brand};
  }
`;

const Artwork = styled.div`
  height: 25rem;
  width: 25rem;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 3rem;
  background: #3e3e3e;
  border-radius: 100%;
  background-image: url("/animations/${(props) => props.artwork}");
  background-position: center;
  background-size: cover;
  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    height: 29.2rem;
    width: 29.2rem;
  }
`;

function createMarkup(data) {
  return {
    __html: data,
  };
}

class WorkflowPage extends React.Component {
  render() {
    const {
      slug,
      title,
      metaData,
      lede,
      body,
      modalities,
      banner,
      touts,
      features,
      tout,
    } = this.props.data.workflowsJson;
    return (
      <Layout current={slug} footerPosition={"relative"}>
        <Seo title={metaData.title} description={metaData.description} />
        <Wrapper>
          <Banner
            desktop={banner.desktop}
            tablet={banner.tablet}
            mobile={banner.mobile}
          />
        </Wrapper>
        <Container>
          <Body>
            <Icon artwork={banner.animation} />
            <Title>{title}</Title>
            <Grid>
              <GridItem>
                <RichText dangerouslySetInnerHTML={createMarkup(lede)} />
                {tout && tout.artwork && (
                  <>
                    <Artwork artwork={tout.artwork} />
                    <Body>
                      <RichText
                        dangerouslySetInnerHTML={createMarkup(tout.text)}
                      />
                    </Body>
                  </>
                )}
              </GridItem>
              {modalities.length > 0 && (
                <GridItem>
                  <Table modalities={modalities} />
                </GridItem>
              )}
            </Grid>
          </Body>
          {touts && touts.length > 0 && <Touts items={touts} />}
          {body && body.length > 0 && (
            <Body>
              <RichText dangerouslySetInnerHTML={createMarkup(body)} />
            </Body>
          )}
          {features && features.length && (
            <>
              {features.map((item, i) => (
                <Feature
                  key={i}
                  title={item.title}
                  text={item.text}
                  artwork={item.artwork}
                  alignment={item.alignment}
                />
              ))}
            </>
          )}
          <Pagination slug={slug} />
        </Container>
      </Layout>
    );
  }
}

export default WorkflowPage;

export const pageQuery = graphql`
  query WorkflowsBySlug($slug: String!) {
    workflowsJson(slug: { eq: $slug }) {
      title
      slug
      metaData {
        title
        description
      }
      lede
      body
      modalities {
        title
        slug
      }
      banner {
        desktop
        tablet
        mobile
        animation
      }
      features {
        title
        text
        artwork
        alignment
      }
      touts {
        title
        subtitle
        artwork
        points
      }
      tout {
        artwork
        text
      }
    }
  }
`;
