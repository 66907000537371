import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Component = styled.div`
  margin-bottom: 14.5rem;
`;

const Grid = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    flex-direction: row;
    align-items: center;
  }
`;

const ItemOne = styled.div`
  width: 100%;
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    order: ${(props) => (props.alignment === "right" ? "1" : "2")};
    width: 50%;
    display: flex;
    justify-content: ${(props) =>
      props.alignment === "right" ? "flex-start" : "flex-end"};
  }
`;

const ItemTwo = styled.div`
  width: 100%;
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    order: ${(props) => (props.alignment === "right" ? "2" : "1")};
    width: 50%;
  }
`;

const Art = styled.div`
  height: 25rem;
  width: 25rem;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 3rem;
  background: #3e3e3e;
  border-radius: 100%;
  background-image: url("/animations/${(props) => props.artwork}");
  background-position: center;
  background-size: cover;
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    height: 30rem;
    width: 30rem;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    height: 43.6rem;
    width: 43.6rem;
  }
`;

const Title = styled.h2`
  margin-bottom: 2rem;
  color: ${({ theme }) => theme.colors.brand};
  font-family: ${({ theme }) => theme.fontFamily};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: 3.6rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin-bottom: 3rem;
    font-size: 3.6rem;
  }
`;

const Text = styled.div`
  max-width: 63rem;
  p {
    line-height: 1.6;
    color: ${({ theme }) => theme.colors.white};
    font-size: 1.5rem;
    font-family: ${({ theme }) => theme.fontFamily};
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
      font-size: 2rem;
      line-height: 1.6;
    }
  }
  p:not(:last-of-type) {
    margin-bottom: 2.5rem;
  }
`;

function createMarkup(data) {
  return {
    __html: data,
  };
}

const Feature = ({ title, text, artwork, alignment }) => {
  return (
    <Component>
      <Grid>
        <ItemOne alignment={alignment}>
          <div>
            <Art artwork={artwork}></Art>
          </div>
        </ItemOne>
        <ItemTwo alignment={alignment}>
          <Title>{title}</Title>
          <Text dangerouslySetInnerHTML={createMarkup(text)} />
        </ItemTwo>
      </Grid>
    </Component>
  );
};

Feature.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  artwork: PropTypes.string.isRequired,
};

export default Feature;
