import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Component = styled.section`
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin-bottom: 6rem;
  }
`;

const Grid = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    flex-direction: row;
  }
`;

const Tout = styled.div`
  width: 100%;
  margin-bottom: 4.8rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    width: 25%;
    margin-bottom: 0;
    padding-right: 2.5rem;
  }
`;

const ToutHeader = styled.div`
  margin-bottom: 2.6rem;
`;

const ToutArtwork = styled.div`
  margin-bottom: 0.8rem;
`;

const ToutTitle = styled.h3`
  margin-bottom: 0.8rem;
  color: ${({ theme }) => theme.colors.brand};
  font-family: ${({ theme }) => theme.fontFamily};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: 2.4rem;
`;

const ToutSubtitle = styled.p`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fontFamily};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  font-size: 1.8rem;
`;

const ToutListItem = styled.li`
  opacity: 0.5;
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fontFamily};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  font-size: 1.6rem;
  &:not(:last-of-type) {
    margin-bottom: 1rem;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    &:not(:last-of-type) {
      margin-bottom: 2.5rem;
    }
  }
`;

const Touts = ({ items }) => {
  return (
    <Component>
      <Grid>
        {items.map((item, i) => (
          <Tout key={i}>
            <ToutHeader>
              <ToutArtwork>
                {item.artwork === "target" && (
                  <svg
                    width="28"
                    height="28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14 24c5.523 0 10-4.477 10-10S19.523 4 14 4 4 8.477 4 14s4.477 10 10 10z"
                      stroke="#FFDF00"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M14 20a6 6 0 100-12 6 6 0 000 12z"
                      stroke="#FFDF00"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M14 16a2 2 0 100-4 2 2 0 000 4z"
                      stroke="#FFDF00"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                )}
                {item.artwork === "magnify" && (
                  <svg
                    width="28"
                    height="28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13 21a8 8 0 100-16 8 8 0 000 16zM23 23l-4.35-4.35"
                      stroke="#FFDF00"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                )}
                {item.artwork === "idea" && (
                  <svg
                    width="28"
                    height="28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M22.094 10.33c0-4.26-3.834-7.705-8.094-7.705s-8.094 3.45-8.094 7.706c0 1.695.722 3.067 1.652 4.38H7.54c.596.82 1.17.968 1.723 1.914.804 1.378.99 2.226 1.023 3.046.022.47.41.837.875.837h.486a.22.22 0 00.22-.219v-5.168c0-.273-.066-.536-.187-.782l-1.148-2.297a.574.574 0 111.028-.508l1.383 2.81c.12.241.181.504.181.772v5.173c0 .12.098.219.219.219h1.312a.22.22 0 00.219-.219v-5.173c0-.268.06-.53.18-.771l1.39-2.822a.55.55 0 11.984.492l-1.165 2.33c-.12.246-.186.514-.186.782v5.162c0 .12.099.219.219.219h.547c.465 0 .847-.361.875-.82.049-.843.257-1.767 1.006-3.063.552-.946 1.127-1.094 1.723-1.914h-.006c.93-1.313 1.652-2.685 1.652-4.38zm-9.078 15.045h1.968a.768.768 0 00.766-.766.768.768 0 00-.766-.765h-1.968a.768.768 0 00-.766.765c0 .421.345.766.766.766zm-1.094-2.406h4.156a.768.768 0 00.766-.766.768.768 0 00-.766-.765h-4.156a.768.768 0 00-.766.765c0 .421.345.766.766.766z"
                      fill="#FFDF00"
                    />
                  </svg>
                )}
                {item.artwork === "chart" && (
                  <svg
                    width="25"
                    height="20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M23.744 18.313H3.456V2.837l.504.505a.845.845 0 001.377-.92.838.838 0 00-.184-.274L3.258.253a.845.845 0 00-1.207 0L.211 2.108A.845.845 0 001.402 3.3l.362-.362v16.212a.845.845 0 00.845.846h21.134a.846.846 0 100-1.684z"
                      fill="#FFDF00"
                    />
                    <path
                      d="M17.888 7.841a.845.845 0 00-.839.845v7.902a.845.845 0 00.846.845h3.109a.845.845 0 00.818-.845V8.686a.845.845 0 00-.839-.845h-3.095zm2.263 7.901h-1.424v-6.21h1.424v6.21zM11.827 1.726a.839.839 0 00-.838.845v14.017a.845.845 0 00.838.845h3.116a.845.845 0 00.838-.845V2.57a.84.84 0 00-.838-.845h-3.116zm2.27 14.017h-1.424V3.41h1.424v12.332zM5.773 5.516a.839.839 0 00-.838.846v10.226a.845.845 0 00.838.845H8.87a.845.845 0 00.845-.845V6.362a.839.839 0 00-.845-.846H5.773zm2.27 10.226H6.62V7.207h1.425v8.535z"
                      fill="#FFDF00"
                    />
                  </svg>
                )}
              </ToutArtwork>
              <ToutTitle>{item.title}</ToutTitle>
              <ToutSubtitle>{item.subtitle}</ToutSubtitle>
            </ToutHeader>
            <ul>
              {item.points.map((point, i) => (
                <ToutListItem key={i}>{point}</ToutListItem>
              ))}
            </ul>
          </Tout>
        ))}
      </Grid>
    </Component>
  );
};

Touts.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      subtitle: PropTypes.string.isRequired,
      artwork: PropTypes.string.isRequired,
      points: PropTypes.array.isRequired,
    }).isRequired
  ).isRequired,
};

export default Touts;
